import '../jquery';

import 'jquery-ui';
import 'jquery-ui/ui/widgets/mouse';

import '@activeadmin/activeadmin';

import "../active_admin/application";
import "../active_admin/fitness_network";
import "../active_admin/text_onboarding";

import select2 from 'select2';
import '@codevise/activeadmin-searchable_select';

select2($);
