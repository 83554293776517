(function() {
  $(document).ready(function() {
    $("body").on("change", "select#company-type-selector", function(event) {
      let selectedType = event.target.value;
      switch (selectedType) {
        case "1":
          $("#private-company-type").removeAttr("disabled");
          $("#private-company-type").show();
          $("#public-company-type").attr("disabled", true);
          $("#public-company-type").hide();
          break;
        case "2":
          $("#public-company-type").removeAttr("disabled");
          $("#public-company-type").show();
          $("#private-company-type").attr("disabled", true);
          $("#private-company-type").hide();
          break;
      }
    });

    $("body").on(
      "change",
      "select#text_onboarding_account_id",
      function(event) {
        let selectedAccountId = event.target.value;

        if (selectedAccountId.length > 0) {
          $("form fieldset:not(#parent-account-fieldset)").attr(
            "disabled",
            true
          );
        } else {
          $("form fieldset:not(#parent-account-fieldset)").removeAttr(
            "disabled"
          );

          switch ($("#company-type-selector").val()) {
            case "1":
              $("#private-company-type").removeAttr("disabled");
              $("#public-company-type").attr("disabled", true);
              break;
            case "2":
              $("#public-company-type").removeAttr("disabled");
              $("#private-company-type").attr("disabled", true);
              break;
          }
        }
      }
    );

    const handleTextingSetupFlagChange = function(checked) {
      if (checked) {
        $("#brand-data-config").removeAttr("disabled");
        $("#brand-data-config").show();
      } else {
        $("#brand-data-config").attr("disabled", true);
        $("#brand-data-config").hide();
      }
    };

    $("body").on("change", "#setup_a2p_and_brand_flag", function(event) {
      handleTextingSetupFlagChange(event.target.checked);
    });

    handleTextingSetupFlagChange($("#setup_a2p_and_brand_flag").is(":checked"));
  });
}).call(this);
