(function() {
  $(document).ready(function() {
    $("#locations-selector input[type=checkbox]").click(function(_) {
      let selectedCount = $(
        "#locations-selector input:checked[type=checkbox]"
      ).length;
      $("#selectedCount").text(selectedCount);
    });
    return $("body").on("click", "#set_mt_subdomain", function(event) {
      var form, mt_subdomain, setButton;
      event.preventDefault();
      setButton = event.target;
      mt_subdomain = $('#fitness_network_mt_subdomain').val();
      if (mt_subdomain.length > 0) {
        form = $('form#edit_fitness_network');
        $(setButton).text('Please Wait...');
        $(setButton).attr('disabled', 'disabled');
        return $.ajax({
          url: form.attr('action'),
          data: form.serialize(),
          type: 'POST',
          dataType: 'text'
        }).done(function(json) {
          $('.admin_ui_fitness_networks').load('edit #wrapper');
        }).fail(function(xhr, status, errorThrown) {
          alert('Sorry, there was a problem!');
        }).always(function(xhr, status) {
          $(setButton).text('Set subdomain');
          $(setButton).attr('disabled', false);
        });
      }
    });
  });
}).call(this);
